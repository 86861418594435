import * as React from "react"
import { Link } from "gatsby"
import Video from "../components/video"
import { StaticImage } from "gatsby-plugin-image"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Media from 'react-bootstrap/Media'
import Button from 'react-bootstrap/Button'

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Vigilant Tutorials" description="Vigilant is a powerful 8-Channel Online Permanently Mounted Condition Monitoring Solution. Watch these three short tutorials detailing Vigilant’s easy to use desktop and workstation." />
    <Jumbotron fluid>
  <Container className="marginTop">
  <Media>
    <Media.Body>
    <StaticImage
      src="../images/vigilant-logo-large.png"
      width={400}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
    <h1>Monitoring Ontario's Wind Farms with Vigilant</h1>
      <p className="leadText">
      Wind farmers power Ontario with clean, renewable energy and if they are forewarned of critical component failure, then they can expect many years of useful, trouble-free life from their assets. But wind turbines are complex assets consisting of both slow and high-speed components which presents unique challenges for condition monitoring technicians. These challenges are overcome with advanced technologies and safety training.
      </p>

      <p className="leadBody">
      One such challenge is the height where data must be collected. The average height of wind turbines in the United States is 280' (85.3m). This poses significant accessibility and safety concerns for condition monitoring technicians.
      </p>

    </Media.Body>
  </Media>
  </Container>
</Jumbotron>

<Container className="marginTop">
      
      <p className="leadBody">
      Another challenge reliability technicians must overcome is the logistical nightmare when failing or failed components need maintaining or replacement. Both require expensive heavy-duty machinery with trained operators for multiple down days. Therefore, planning these interventions weeks or months ahead of time is the best way to minimize cost overruns, or mitigate them altogether.
      </p>

      <p className="leadBody">
      The third challenge condition monitoring technicians face is the differing rotational speeds of their wind turbine’s components. Vibration analysis works well for monitoring assets that rotate fast, such as the drive end and non-drive end bearings on a main generator. Where vibration falters is on the turbines slow rotating components. The main bearing on a wind turbine turns much slower than the generator’s bearings. Slow-speed bearings don’t create enough vibration for it to be an effective monitoring tool. To monitor slow-rotating assets, condition monitoring technicians put their trust in ultrasound technology.
      </p>

      <h2>Accessibility</h2>

<p className="leadBody">
The most glaring accessibility challenge faced is the height at condition monitoring technicians must perform their data collection. Before even ascending a wind turbine, technicians must complete a mandatory eight plus hours Working at Heights safety training course.
      </p>

      <p className="leadBody">
      Whenever working at extreme heights, the element of employee safety requires consideration. This is especially true for technicians working on turbines without lifts. Technicians climbing 50-100 metres up a ladder have the extra element of fatigue amplifying their safety concerns, especially when this ascent is made multiple times in a single day. Additionally, ascending and descending wind turbines all day uses up a technician’s valuable time. When time becomes scarce it often borrows common sense for companionship.
      </p>

      <p className="leadBody">
      A permanent condition monitoring solution that streams data to a wind farm’s maintenance headquarters is ideal for extreme heights. It reduce’s the need for frequent trips up and down wind turbines, and improve the overall efficiency of the maintenance and reliability department’s operation.
      </p>

      <h2>Forewarning of Critical Component Failure</h2>

      <p className="leadBody">
      When critical components inside the nacelle fail, maintenance crews must act swiftly to restore operation to the turbine. Repairing and replacing large components at extreme heights proves a difficult and expensive challenge. Heavy-duty cranes and skilled labour are necessary expenses for performing this maintenance on wind turbines. Finding faults and predicting impending failures far in advance reduce the cost and headache of coordinating these maintenance efforts.
      </p>

      <p className="leadBody">
      A common model outlining the typical life of an asset is the D-I-P-F curve. As shown in the following diagram, ultrasound technology owns the APEX of the D-I-P-F curve. Ultrasound detects impending and potential failures earlier than vibration, infrared or any other condition monitoring technology thereby providing planning and scheduling with the largest window of opportunity for corrective repair.
      </p>

      <StaticImage
      src="../images/p-f.png"
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      objectFit="contain"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />

    <h2>Inside the Nacelle</h2>
    <p className="leadBody">Choosing the right condition monitoring technology enables maintenance and reliability technicians to perform their job better and more efficiently. A look inside the nacelle depicts a wind turbine’s critical components. The main bearing connects the turbine blades to the rest of the mechanism. This main turbine shaft turns around 10-20 rotations per minute, depending on wind speeds. The turbine’s gearbox, and the generator drive end, and non-drive end turn much faster than the slow-speed main shaft.
    </p>

    
    <StaticImage
      src="../images/wind_turbine_nacelle.jpg"
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      objectFit="contain"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />

    <h2>Success Story</h2>

    <p className="leadBody">
    SDT Ultrasound Solutions representatives Robert Dent, Mark Nanni, and Matt Jeffrey recently installed the first Vigilant System on a 90-metre-tall wind turbine in Southwestern Ontario.
    </p>

    <p className="leadBody">
    Vigilant is a permanent condition monitoring solution with both ultrasound and vibration analysis capabilities. Each Vigilant pod is capable of managing inputs from eight data sources continuously (no multi-plexing). Condition monitoring data streams live, via WIFI, ethernet or cellular, to its onboard trending and analysis application. Reliability teams are fed a constant diet of information about the health of their remote and critical assets. Complex analysis tools normally reserved for expensive, high-end vibration software are readily available to analysts of any skill level. And because the application is web-based, there’s no tricky licensing setups to hassle over. Simply login with your favourite browser and start analyzing!
    </p>

    <p className="leadBody">
    Vibration analysis was already being utilized to permanently monitor the turbines critical components. Vigilant was deployed to monitor seven key data collection points using ultrasound. One data collection point was on the slow-speed main bearing, four were on the gearbox, and one data collection point was put on both the drive-end and non-drive-end of the turbine’s generator.Ultrasound is optimal for monitoring the slow-speed main bearing of the turbine, which turns 91X slower than the bearings on the main generator. Receptive to microsonic changes in low energy events caused by friction and impacts, ultrasound warns us when changes to safe, normal operating parameters reveal imminent failure. With Vigilant installed, all key data collection points now benefit from a more complete analysis with smart data from both ultrasound and vibration.
    </p>

    <p className="leadBody">
    SDT Personnel were able to complete the installation very quickly. In under two hours condition monitoring data was streaming from the Vigilant System 90 meters in the air, to the wind farm’s maintenance office. 
    </p>

    <h2>Analysis</h2>

    <StaticImage
      src="../images/impacting1.png"
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      objectFit="contain"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />

    <p className="leadBody">
    Planetary Bearing - As expected from the initial vibration data, ultrasound reading confirmed the presence of impacting.
    </p>

    <StaticImage
      src="../images/impacting2.png"
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      objectFit="contain"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />

    <p className="leadBody">
    Slow-Speed Main Bearing - The initial ultrasound reading on the main bearing came back higher than expected with lots of impacting. SDT’s recommendation was to lubricate the bearing. Ultrasound decibel levels dropped afterwards, indicating a reduction in both friction and impacting as seen in the figure below.
    </p>

    <StaticImage
      src="../images/greasereplenishment1.png"
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      objectFit="contain"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
    <br />

<StaticImage
      src="../images/BPFO1.png"
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      objectFit="contain"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
<StaticImage
      src="../images/BPFO2.png"
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      objectFit="contain"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
    <StaticImage
      src="../images/BPFO3.png"
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions - Vigilant Logo"
      objectFit="contain"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />

    <p className="leadBody">
    Additional analysis was conducted on the main bearing. Repetitive impacts were discovered at a BPFO of approximately 13.5x running speed, consistent with an outer race defect. SDT’s recommendation was to closely monitor the condition of the main bearing. The defect will eventually result in the main bearing needing to be replaced.
    </p>

    <p className="leadBody">
    The wind turbine maintenance technicians benefitted from installing the Vigilant System by gaining 7 permanent ultrasound data collection points on the critical components of their wind turbine. Using their new data, they were able to confirm a suspected defect on the planetary bearing which was originally detected using vibration analysis. Additionally, they found a new defect on the main bearing that went undetected by the vibration sensors already in place. After applying some grease to the main bearing, the technicians noticed a significant drop in friction levels. They will continue to monitor the main bearing closely moving forward.
    </p>
  </Container>




  <Container className="marginTop">
  <hr />
  <Media>
    <Media.Body>
      <p className="leadBody">
      SDT’s Permanent Monitoring solutions contribute to the reliability of your most critical assets while improving overall efficiency of your operations.
      </p>

      <p className="leadBody">
      Real-time Ultrasound and Vibration data indicate changes in healthy function earlier in the P-F Curve than other condition monitoring technologies. Advanced warning allows every maintenance job to be planned while reducing levels of critical spares held in inventory. Reliability teams benefit from a proactive culture that affords them time to eliminate defects rather than simply applying band-aids.
      </p>
      <Link className="btn btn-primary" to="/">Home</Link>&nbsp;&nbsp;<Button href="http://ftp.sdt.be/pub/Products/VIGILANT/Brochure/Vigilant_Brochure_EN.pdf">Download Brochure</Button> 
    </Media.Body>
    <StaticImage
      src="../images/vigilant-2.jpg"
      width={400}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="SDT Ultrasound Solutions Vigilant"
      className="align-self-start mr-3"
      style={{ marginBottom: `1.45rem` }}
    />
  </Media>
  </Container>
  
  </Layout>
)

export default IndexPage
